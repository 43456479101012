import { MIDDLEWARES, ROUTES } from "@/utils/consts";

export const billsRoutes = {
  path: ROUTES.BILLS.path,
  component: () => import("@/pages/payments/index.vue"),
  meta: {
    middlewares: { [MIDDLEWARES.PROTECTED]: true },
    displayName: ROUTES.BILLS.displayName,
  },
  props: { backPath: ROUTES.INDEX.path },
  children: [
    {
      path: "",
      name: ROUTES.BILLS.name,
      component: () => import("@/pages/payments/actions/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.BILLS.displayName,
      },
    },
    {
      path: ROUTES.BILLS.AIRTIME.path,
      name: ROUTES.BILLS.AIRTIME.name,
      component: () => import("@/pages/payments/actions/airtime/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.BILLS.AIRTIME.displayName,
      },
    },
    {
      path: ROUTES.BILLS.ELECTRIC_BILL.path,
      name: ROUTES.BILLS.ELECTRIC_BILL.name,
      component: () =>
        import("@/pages/payments/actions/electric-bills/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.BILLS.ELECTRIC_BILL.displayName,
      },
    },
    {
      path: ROUTES.BILLS.CABLE_TV.path,
      name: ROUTES.BILLS.CABLE_TV.name,
      component: () => import("@/pages/payments/actions/cable-tv/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.BILLS.CABLE_TV.displayName,
      },
    },
    {
      path: ROUTES.BILLS.DATA_PLAN.path,
      name: ROUTES.BILLS.DATA_PLAN.name,
      component: () => import("@/pages/payments/actions/data-bundle/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.BILLS.DATA_PLAN.displayName,
      },
    },
  ],
};

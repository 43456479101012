export const swapRoutes = {
  path: "swap/:asset?",
  component: () => import("@/pages/wallet/actions/index.vue"),
  children: [
    {
      path: "",
      component: () => import("@/pages/wallet/actions/trade/trade-wrapper.vue"),
    },
  ],
};

import { MIDDLEWARES, ROUTES } from "@/utils/consts";

export const transactionRoutes = {
  path: ROUTES.TRANSACTIONS.path,
  name: ROUTES.TRANSACTIONS.name,
  exact: true,
  component: () => import("@/pages/transactions/index.vue"),
  meta: {
    middlewares: { [MIDDLEWARES.PROTECTED]: true },
    displayName: ROUTES.TRANSACTIONS.displayName,
  },
  children: [
    {
      path: ROUTES.TRANSACTIONS.DETAILS.path,
      name: ROUTES.TRANSACTIONS.DETAILS.name,
      component: () => import("@/pages/transactions/show.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.TRANSACTIONS.DETAILS.displayName,
      },
    },
    {
      path: ROUTES.TRANSACTIONS.SORT_BY.path,
      name: ROUTES.TRANSACTIONS.SORT_BY.name,
      component: () => import("@/pages/transactions/sort.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.TRANSACTIONS.SORT_BY.displayName,
      },
    },
  ],
};

import { ROUTES } from "@/utils/consts";
import { RouteLocationNormalized } from "vue-router";

export const withdrawalRoutes = {
  path: "withdraw/",
  component: () => import("@/pages/wallet/actions/index.vue"),
  props: { backPath: ROUTES.INDEX.path },
  children: [
    {
      path: "",
      component: () =>
        import("@/pages/wallet/actions/withdraw/select-category.vue"),
      props: {
        action: "withdraw",
        parentRoute: "",
        backPath: "/",
      },
    },
    {
      path: ":category(crypto|fiat)",
      component: () =>
        import("@/pages/wallet/actions/withdraw/select-sub-category.vue"),
      props: {
        action: "withdraw",
        parentRoute: "withdraw",
        backPath: "/withdraw",
      },
    },
    {
      path: ":category(crypto)/:method(internal|external)",
      component: () =>
        import("@/pages/wallet/actions/withdraw/select-wallet-wrapper.vue"),
      props: { backPath: "/withdraw/crypto" },
    },
    {
      path: ":category(fiat)/:method(banks|momo|apex)",
      component: () =>
        import("@/pages/wallet/actions/withdraw/select-wallet-wrapper.vue"),
      props: { backPath: "/withdraw/fiat" },
    },
    {
      path: ":category(crypto)/:method(internal|external)/:asset",
      component: () =>
        import("@/pages/wallet/actions/withdraw/withdrawal-mode.vue"),
      props: (route: RouteLocationNormalized) => ({
        backPath: "/",
        selectWalletPath: `/withdraw/${route.params.category}`,
      }),
    },
    {
      path: ":category(fiat)/:method(banks|momo|apex)/:asset",
      component: () =>
        import("@/pages/wallet/actions/withdraw/withdrawal-mode.vue"),
      props: (route: RouteLocationNormalized) => ({
        backPath: "/",
        selectWalletPath: `/withdraw/${route.params.category}`,
      }),
    },
  ],
};

import { MIDDLEWARES, ROUTES } from "@/utils/consts";

export const cardsRoutes = {
  path: ROUTES.CARDS.path,
  name: ROUTES.CARDS.name,
  component: () => import("@/pages/cards/index.vue"),
  meta: {
    middlewares: { [MIDDLEWARES.PROTECTED]: true },
    displayName: ROUTES.CARDS.displayName,
  },
  children: [
    {
      path: ROUTES.CARDS.CREATE.path,
      name: ROUTES.CARDS.CREATE.name,
      component: () => import("@/pages/cards/create/CreateCardWrapper.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.CARDS.CREATE.displayName,
      },
    },
    {
      path: ROUTES.CARDS.FUND.path,
      name: ROUTES.CARDS.FUND.name,
      component: () => import("@/pages/cards/fund/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.CARDS.FUND.displayName,
      },
    },
    {
      path: ROUTES.CARDS.WITHDRAW.path,
      name: ROUTES.CARDS.WITHDRAW.name,
      component: () => import("@/pages/cards/withdraw/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.CARDS.WITHDRAW.displayName,
      },
    },
    {
      path: ROUTES.CARDS.DETAILS.path,
      name: ROUTES.CARDS.DETAILS.name,
      component: () => import("@/pages/cards/show.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.CARDS.DETAILS.displayName,
      },
    },
    {
      path: ROUTES.CARDS.ALL.path,
      name: ROUTES.CARDS.ALL.name,
      component: () => import("@/pages/cards/list/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.CARDS.ALL.displayName,
      },
    },
    {
      path: ROUTES.CARDS.MORE.path,
      name: ROUTES.CARDS.MORE.name,
      component: () => import("@/pages/cards/more/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.CARDS.MORE.displayName,
      },
    },
    {
      path: ROUTES.CARDS.TRANSACTION.path,
      name: ROUTES.CARDS.TRANSACTION.name,
      component: () => import("@/pages/cards/transaction/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.CARDS.TRANSACTION.displayName,
      },
    },
    {
      path: ROUTES.CARDS.EDIT_LABEL.path,
      name: ROUTES.CARDS.EDIT_LABEL.name,
      component: () => import("@/pages/cards/label/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.CARDS.EDIT_LABEL.displayName,
      },
    },
  ],
};

import { MIDDLEWARES, ROUTES } from "@/utils/consts";

export const giftCardRoutes = {
  path: ROUTES.GIFT_CARD_SELL.path,
  name: ROUTES.GIFT_CARD_SELL.name,
  component: () => import("@/pages/giftcards.vue"),
  meta: {
    middlewares: { [MIDDLEWARES.PROTECTED]: true },
    displayName: ROUTES.GIFT_CARD_SELL.displayName,
  },
  children: [
    {
      path: ROUTES.GIFT_CARD_SELL.path,
      name: ROUTES.GIFT_CARD_SELL.name,
      component: () => import("@/pages/giftcards/index.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.GIFT_CARD_SELL.displayName,
      },
      children: [
        {
          path: ROUTES.GIFT_CARD_SELL.OFFER.path,
          name: ROUTES.GIFT_CARD_SELL.OFFER.name,
          component: () => import("@/pages/giftcards/giftcards-sell/index.vue"),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.GIFT_CARD_SELL.displayName,
          },
        },
      ],
    },
    {
      path: ROUTES.GIFT_CARD_BUY.path,
      name: ROUTES.GIFT_CARD_BUY.name,
      component: () => import("@/pages/giftcards/GiftcardBuy.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.GIFT_CARD_BUY.displayName,
      },
      children: [
        {
          path: ROUTES.GIFT_CARD_BUY.OFFER.path,
          name: ROUTES.GIFT_CARD_BUY.OFFER.name,
          component: () => import("@/pages/giftcards/giftcards-buy/index.vue"),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.PROFILE.displayName,
          },
        },
      ],
    },
    {
      path: ROUTES.GIFT_CARD_TRADES.path,
      name: ROUTES.GIFT_CARD_TRADES.name,
      component: () => import("@/pages/giftcards/GiftcardTrades.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.GIFT_CARD_TRADES.displayName,
      },
    },
    {
      path: ROUTES.GIFT_CARD_TRADE_CHAT.path,
      name: ROUTES.GIFT_CARD_TRADE_CHAT.name,
      sensitive: true,
      component: () => import("@/pages/giftcards/GiftcardTradeChat.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.GIFT_CARD_TRADE_CHAT.displayName,
      },
    },
  ],
};

import { ROUTES } from "@/utils/consts";

export const depositRoutes = {
  path: "deposit/",
  component: () => import("@/pages/wallet/actions/index.vue"),
  props: { backPath: ROUTES.INDEX.path },
  children: [
    {
      path: "",
      component: () => import("@/pages/wallet/actions/select-category.vue"),
      props: {
        action: "deposit",
        parentRoute: "",
      },
    },
    {
      path: "local",
      component: () => import("@/pages/wallet/actions/deposit/fiat/index.vue"),
      props: { backPath: `/deposit/` },
    },
    {
      path: ":category",
      component: () =>
        import("@/pages/wallet/actions/deposit/select-wallet-wrapper.vue"),
      props: { backPath: `/deposit/` },
    },
    {
      path: ":category/:asset",
      component: () =>
        import("@/pages/wallet/actions/deposit/deposit-wrapper.vue"),
      props: (route: any) => ({
        backPath: `/deposit/${route.params.category}`,
      }),
    },
  ],
};

import { TRANSACTION_STATUS_TYPE } from "@/shared/enums/transactions";
import {
  IWallet,
  IWalletLimit,
  IWalletsTotalAPIResponse,
} from "@/shared/types/wallets";
import { socialLinkName } from "@/types/referrals.types";
import { helpers } from "@vuelidate/validators";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { useCookies } from "vue3-cookies";

export const formatDate = (value: string, option: string) => {
  return dayjs(value).format(option);
};

export const reload = () => {
  return window.location.reload();
};

// sort coins by name and symbol
export const sortCoinsByName = (coins: IWallet[]) => {
  return coins.sort((a, b) => {
    if (a.assetName < b.assetName) {
      return -1;
    }
    if (a.assetName > b.assetName) {
      return 1;
    }
    return 0;
  });
};

// sort coins by price
export const sortCoinsByPrice = (coins: IWallet[]) => {
  return coins.sort((a, b) => {
    return b.rate - a.rate;
  });
};

//sort coins by market price
export const sortCoinsByMarketCap = (coins: IWallet[]) => {
  return coins.sort((a, b) => {
    return b?.rate - a?.rate;
  });
};

// truncate decimal amounts to specified number of decimal places. Default => Fiat: 2, Coin: 8
export const truncateDecimalPrice = (price: number, digits: number) => {
  return Math.trunc(price * Math.pow(10, digits)) / Math.pow(10, digits);
};

export const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getNairaExchangeRate = () => {
  return 700;
};

export const numericWithSpace = helpers.regex(/^[0-9\s]*$/);

// remove the countryCode
export const toRegularPhone = (phone: string) => {
  const replaced = phone.slice(1);

  return replaced;
};
export const removeUnderscores = (word: string) => {
  return word.replace("_", " ");
};

export const moneyFormat = (number: number) => {
  return new Intl.NumberFormat("en-NG").format(number);
};

export const formattedTransactionDate = (date: string = "") => {
  return `${formatDate(date, "DD MMM, YYYY")} | ${formatDate(date, "HH:mm A")}`;
};

export const getCurrentDate = () => {
  return formatDate(new Date().toISOString(), "DD MMM, YYYY");
};

export const generateRandomColors = (length: number): string[] => {
  let colors: string[] = [];
  for (let i = 0; i < length; i++) {
    let color: string = "#";
    for (let j = 0; j < 6; j++) {
      color += Math.floor(Math.random() * 16).toString(16);
    }
    colors.push(color);
  }
  return colors;
};

export const toMoneyFormat = (
  value: string | number,
  country: string = "en-NG",
  currency: string = "NGN"
) => {
  if (typeof value !== "number") return value;
  return new Intl.NumberFormat(country, {
    style: "currency",
    currency,
  }).format(value);
};

export const generateRandomUUIDString = () => {
  // This function generates random string identifier using base 36 (numbers + letters) and current timestamp
  return (
    new Date().getTime().toString(36) + Math.random().toString(36).slice(2)
  );
};

export const validateImage = (file: File) => {
  const MAX_SIZE = 1024 * 1024; // 1 MB

  if (!file.type.includes("image/")) {
    return [false, "Only image files are accepted."];
  }

  if (file.size > MAX_SIZE) {
    return [false, "Image is larger than 1MB"];
  }

  return [true, ""];
};

export const isoCodeToFlagEmoji = (isoCode: string) => {
  if (!isoCode) return "";
  const splittedText = isoCode.split("_");
  const flagOffset = 0x1f1e6 - 0x41; // subtract 0x41 since 'A' is at position 0x41 in the ASCII table
  const upperCaseIsoCode = splittedText[splittedText.length - 1].toUpperCase();
  const firstChar = upperCaseIsoCode.charCodeAt(0) + flagOffset;
  const secondChar = upperCaseIsoCode.charCodeAt(1) + flagOffset;

  return String.fromCodePoint(firstChar, secondChar);
};

export const returnActiveIfTradeStatusIsPending = (status: string) => {
  if (status === TRANSACTION_STATUS_TYPE.PENDING) {
    return TRANSACTION_STATUS_TYPE.ACTIVE;
  } else return status;
};

export const tradeIsPaidOrCancelled = (status: string = "") => {
  status = status.toLowerCase();
  return (
    status === TRANSACTION_STATUS_TYPE.PAID ||
    status === TRANSACTION_STATUS_TYPE.CANCELLED
  );
};

export const getCreatedAtDate = (createdAt: string) => {
  return new Date(createdAt).toDateString().split(" ").slice(1).join(" ");
};

export const getCreatedAtTime = (createdAt: string) => {
  return new Date(createdAt).toLocaleTimeString("en-us", {
    timeStyle: "short",
  });
};

export const returnCurrencySymbol = (currency: string) => {
  if (currency) {
    return new Intl.NumberFormat(`en-${currency?.slice(0, 2)}`, {
      style: "currency",
      currencyDisplay: "symbol",
      currency,
    })
      .format(0)
      .replace(/\d/g, "")
      .substring(0, 1);
  } else return "";
};

export const clearCookie = () => {
  const { cookies } = useCookies();
  ["token", "expiresAt"].forEach((cookie) => cookies.remove(cookie));
};

// get total wallets
export const generateTotalWallets = (
  walletsTotal: IWalletsTotalAPIResponse[],
  allWallets: IWallet[]
) => {
  return walletsTotal.map((totalWallet: IWalletsTotalAPIResponse) => {
    return {
      asset: totalWallet.asset,
      total: totalWallet.total || 0,
      imageUrl: totalWallet.imageUrl || "",
      symbol: totalWallet.symbol,
    };
  });
};

export const getInputError = (
  errors: { [key: string]: string[] | string },
  name: string
) => {
  if (errors && name in errors) {
    return errors[name];
  } else return "";
};

export const camelCaseToSpaces = (word: string) => {
  return word.split(/(?=[A-Z])/).join(" ");
};

export const getSizeWithUnit = (size: number) => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let unitIndex = 0;
  while (size > 1024) {
    size /= 1024;
    unitIndex++;
  }
  return `${size.toFixed(2)} ${units[unitIndex]}`;
};

export const formatMoney = (
  amount: number,
  asset: string = "usd",
  symbol: string | undefined = undefined,
  precision: number = 8
) => {
  return ` 
  ${Number(amount).toLocaleString(undefined, {
    maximumFractionDigits: precision,
  })} ${symbol ? symbol : asset}`;
};

export const amountInPrecision = (
  amount: number | undefined,
  significantFigures: number
) => {
  if (amount) {
    // Convert to string to manipulate
    let numberString = amount.toPrecision(significantFigures + 1); // Adding 1 to include the decimal point

    // Convert back to number and return
    return parseFloat(numberString);
  }
  return 0;
};

export const passwordValidator = (password: string) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
  return passwordRegex.test(password);
};

export const pannedCardNumber = (cardNumber: string) => {
  let maskedChars = "*".repeat(12);
  const formattedNumber = maskedChars + cardNumber.slice(12);
  return formattedNumber.match(/.{1,4}/g)?.join(" ");
};

export const toFirstLetterUppercase = (text: string) => {
  return text.slice(0, 1).toUpperCase() + text.slice(1) || "";
};

export const openLink = (link: string) => {
  window.open(link, "_blank");
};

export const handleReferralShare = (
  platform: socialLinkName,
  referralCode: string,
  baseUrl: string
) => {
  const baseText =
    "Join me on Apex and trade your gift cards and crypto. Use my referral code: " +
    referralCode +
    ` to sign up. ${baseUrl}/create-account?ref=` +
    referralCode;

  switch (platform) {
    case "twitter":
      return openLink(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(baseText)}`
      );
    case "facebook":
      return openLink(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          "https://app.apexnetwork.co"
        )}`
      );
    case "telegram":
      return openLink(
        `https://t.me/share/url?url=${encodeURIComponent(
          "https://app.apexnetwork.co"
        )}&text=${encodeURIComponent(baseText)}`
      );
    case "instagram":
      // Instagram does not support direct sharing through URL, it's typically done via the app.
      // You can open Instagram with pre-filled text and allow the user to share manually.
      return openLink("instagram://app");
    case "whatsapp":
      return openLink(`https://wa.me/?text=${encodeURIComponent(baseText)}`);
    default:
      return;
  }
};

export const toNaira = (amount: number) => {
  return amount?.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  });
};

export const getDecodedToken = (token: string) => {
  return jwtDecode(token) as {
    id: string;
    maxTime: number;
    medium: string;
  };
};

export const getUTCDate = () => {
  const date = new Date(
    new Date().toLocaleString("en-US", { timeZone: "UTC" })
  );

  const formattedDate = date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
    timeZoneName: "short",
  });

  return formattedDate.replace(" at ", ", ");
};

export const getCountryCurrencySymbol = (country: string) => {
  const currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: country,
  }).format(0);
  return currency.charAt(0);
};

export const getLimitForWalletAsset = (asset: IWallet, limit: string) => {
  if (asset) {
    const currLimit = asset.limits[limit as keyof IWalletLimit];
    return amountInPrecision(currLimit, 1);
  } else return "_";
};

export const getFilteredQueryParams = <T extends Record<string, unknown>>(
  queryParams: T
): string => {
  return Object.entries(queryParams)
    .filter(([_, value]) => value != null && value !== "")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
};

export const getTime = (dateStr: string, current: boolean = true): string => {
  let timeValue = Date.now() - Date.parse(dateStr);

  if (current) {
    if (timeValue / 1000 < 60) {
      return "now";
    } else if (timeValue / 1000 / 60 < 60) {
      let temp = Math.floor(timeValue / 1000 / 60);
      return temp == 1 ? temp + " min ago" : temp + " mins ago";
    } else if (timeValue / 1000 / 60 / 60 < 24) {
      let temp = Math.floor(timeValue / 1000 / 60 / 60);
      return temp == 1 ? temp + " hour ago" : temp + " hours ago";
    } else if (timeValue / 1000 / 60 / 60 / 24 < 3) {
      let temp = Math.floor(timeValue / 1000 / 60 / 60 / 24);
      return temp == 1 ? temp + " day ago" : temp + " days ago";
    }
  }

  let date = new Date(dateStr);
  let dateArray = date.toDateString().split(" ");
  return `${dateArray[1]} ${dateArray[2]}, ${
    dateArray[3]
  } at ${date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })}`;
};
